import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-card-widget',
  templateUrl: './card-widget.component.html',
  styleUrls: ['./card-widget.component.scss']
})
export class CardWidgetComponent implements OnInit {

  @Input()
  public label: string = '';

  @Input()
  public amount: number | string = 0;

  @Input()
  public status: 'up' | 'down' = 'up';

  @Input()
  public number: number = 0;

  @Input()
  public amountClass: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  get statusColor(): string {
    return this.status === 'up' ? '#2AB51E' : '#D40101'
  }

}
