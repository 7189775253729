
import { Pipe, PipeTransform } from '@angular/core';
import { IPageType, GroupedMenu } from '../interfaces/page.interface';
import { name } from '@azure/msal-angular/packageMetadata';

@Pipe({
  name: 'groupMenu',
  standalone: true
})
export class GroupMenuPipe implements PipeTransform {

  transform(menus: IPageType[]): Partial<GroupedMenu | IPageType[]> {
    let result: Partial<GroupedMenu | IPageType[]> = {};
    
    for (const [key, item] of Object.entries(menus)) {
      if('isGroup' in item) {
        const groupedItems = menus.filter(item => item.isGroup);
        result = {
          isGroup: true,
          categories: groupedItems.map(group => ({
            name: group.groupName || '',
            menus: menus.filter(item => item.groupName === group.groupName && item.isGroup === true)
          }))
        }
      } else {
        result = menus;
      }
    }

    // console.log(result);
    return result;
  }

}
