<div mat-dialog-title>
  <div class="justify-end p-8" >
    <span class="svg-24 c-pointer" [inlineSVG]="icon.closeBlack.src"
          (click)="close()"
    ></span>
  </div>
</div>

<div mat-dialog-content>
  <div class="dialog-content">
    <div class="dialog-body">
      <div class="dialog-header">

        <div>
          <span [inlineSVG]="stripLogo"></span>
        </div>

        <h1 class="font-24px fw-600 tc-black text-center">
          Roam Stay, LLC uses Stripe to connect your account
        </h1>

      </div>

      <div class="mt-24">
        <div class="align-center gap-10 mb-8" *ngFor="let list of lists">
          <div>
            <span class="d-flex svg-20" [inlineSVG]="list.icon"></span>
          </div>
          <label class="font-14px tc-black">{{ list.name }}</label>
        </div>
      </div>
    </div>

    <div class="dialog-action font-14px text-center mb-24">
      <div class="text-info">By continuing, you agree to Stripe’s Terms
        <a class="tc-primary"></a> and <a class="tc-primary">Privacy Policy</a>,
        <a class="tc-primary">Learn more</a>
      </div>

      <div class="mtb-24">
        <app-roam-button [loader]="false"
                        [label]="'Agree and Continue'"
                        [full]="true"
                        (onClick)="confirmLLLStripe()" />
      
      </div>
      <div>
        <span class="tc-primary">Manually verify instead</span><span>(takes 1-2 business days)</span>
      </div>
    </div>
  </div>
</div>
