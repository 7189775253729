<app-roam-card [title]="'Audit Log'">
  <div actRef>
    <ng-content select="[actRef]"></ng-content>
  </div>

  <ng-container cardContent>
    @if (logs() && logs().length > 0) {
      @for (log of logs(); track log) {
        <div class="activity">
          <div class="log">
            <div>
              @if (log?.data?.totalBalance && +log.data.totalBalance > 0) {
                <span class="font-91466A fw-500">{{ log.data.totalBalance | currencyIntl }}</span>
              }
              <span [ngClass]="{'font-91466A fw-500': log?.data?.totalBalance && +log.data.totalBalance === 0}">
                {{ log?.data?.importId }}
              </span>
              <span>{{ log?.data?.name }}</span>
            </div>
            <div>
              <span class="tc-grey">
                Created: <span class="tc-black fw-400" 
                  [style.textDecoration]="'underline'"
                  [style.marginRight]="0">{{ log.userName }}</span> on {{ log.createdAt | date:'MM/dd/yyyy'  }}
              </span>
            </div>
          </div>
        </div>
      }
    } @else {
      <div class="tc-grey font-14">Audit Log Not Available</div>
    }
  </ng-container>
</app-roam-card>
