import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RoamCardComponent } from "./roam-card/roam-card.component";
import { MatCardModule } from "@angular/material/card";
import { TextLabelDescComponent } from "./text-label-desc/text-label-desc.component";
import { CardWidgetComponent } from "./card-widget/card-widget.component";
import { RoamLogActivityComponent } from "./roam-log-activity/roam-log-activity.component";
import { CardDocumentComponent } from "./card-document/card-document.component";
import { RoamLayoutSidebarComponent } from "./roam-layout-sidebar/roam-layout-sidebar.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { QuickActionTextComponent } from "./quick-action-text/quick-action-text.component";
import { IconRoundComponent } from "./icon-round/icon-round.component";
import { RoamLayoutContainerMenuComponent } from "./roam-layout-container-menu/roam-layout-container-menu.component";
import { RoamSidebarMenuComponent } from "./roam-sidebar-menu/roam-sidebar-menu.component";
import { InlineSVGModule } from "ng-inline-svg-2";
import { CardIconComponent } from "./card-icon/card-icon.component";
import { RoamDialogComponent } from "./roam-dialog/roam-dialog.component";
import { CardFilePreviewComponent } from "./card-file-preview/card-file-preview.component";
import { ButtonCustomizeComponent } from "./button-customize/button-customize.component";
import { PostSectionComponent } from "./post-section/post-section.component";
import { EllipsisModule } from "@app/shared/pipes/ellipsis/ellipsis.module";
import { CardRecentComponent } from "./card-recent/card-recent.component";
import { CardWidgetDashboardComponent } from "./card-widget-dashboard/card-widget-dashboard.component";
import { ActionListSelectionComponent } from "./action-list-selection/action-list-selection.component";
import { CheckboxCardComponent } from "./checkbox-card/checkbox-card.component";
import { ButtonFileComponent } from "./button-file/button-file.component";
import { RoamLayoutWrapperComponent } from "./roam-layout-wrapper/roam-layout-wrapper.component";
import { ImageViewComponent } from "@app/shared/components/roam-layout/image-view/image-view.component";
import { MatIconModule } from "@angular/material/icon";
import { RoamLoaderModule } from "@app/shared/components/roam-loader/roam-loader.module";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OverlayModule } from "@angular/cdk/overlay";
import { RoamIconComponent } from "../roam-icon/roam-icon.component";
import { RoamLogActivityTimelineComponent } from "./roam-log-activity-timeline/roam-log-activity-timeline.component";

@NgModule({
  declarations: [
    CardWidgetComponent,
    RoamLogActivityComponent,
    CardDocumentComponent,
    CardIconComponent,
    RoamDialogComponent,
    CardFilePreviewComponent,
    ButtonCustomizeComponent,
    PostSectionComponent,
    CardRecentComponent,
    CardWidgetDashboardComponent,
    CheckboxCardComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatSidenavModule,
    InlineSVGModule,
    EllipsisModule,
    ImageViewComponent,
    MatIconModule,
    RoamLoaderModule,
    ButtonModule,
    MatProgressSpinnerModule,
    TextLabelDescComponent,
    IconRoundComponent,
    OverlayModule,
    RoamSidebarMenuComponent,
    RoamCardComponent,
    RoamLayoutSidebarComponent,
    RoamLayoutWrapperComponent,
    QuickActionTextComponent,
    RoamLayoutContainerMenuComponent,
    ButtonFileComponent,
    ActionListSelectionComponent,
    RoamIconComponent,
    RoamLogActivityTimelineComponent,
  ],
  exports: [
    RoamCardComponent,
    RoamLogActivityComponent,
    TextLabelDescComponent,
    CardWidgetComponent,
    CardDocumentComponent,
    RoamLayoutSidebarComponent,
    QuickActionTextComponent,
    IconRoundComponent,
    RoamLayoutContainerMenuComponent,
    RoamSidebarMenuComponent,
    CardIconComponent,
    RoamDialogComponent,
    CardFilePreviewComponent,
    ButtonCustomizeComponent,
    PostSectionComponent,
    CardRecentComponent,
    CardWidgetDashboardComponent,
    ActionListSelectionComponent,
    CheckboxCardComponent,
    ButtonFileComponent,
    RoamLayoutWrapperComponent,
    ImageViewComponent,
    RoamLoaderModule,
    ButtonModule,
    MatProgressSpinnerModule,
    RoamLogActivityTimelineComponent,
  ],
})
export class RoamLayoutModule {}
