<div class="card-widget">
  <div>
    {{ label }}
  </div>

  <div class="font-20px" [class]="amountClass">
    {{ amount }}
  </div>

  <div class="align-center">
    <img alt="arrow Icon" class="c-pointer"
         [src]="status === 'up' ? '/assets/svg/ic-up.svg' : '/assets/svg/ic-down.svg'">

    <span [style.color]="statusColor"> {{ number }} <span class="tc-dust">this month</span></span>
  </div>
</div>
