import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { FileFilterFormService } from "@shared/services";
import { RoamFilterDropdownFromToComponent } from "../roam-filter-dropdown-from-to";
import { RoamFilterDropdownTextComponent } from "../roam-filter-dropdown-text";

@Component({
  selector: "app-file-filter-group",
  templateUrl: "./file-filter-group.component.html",
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RoamFilterDropdownFromToComponent,
    RoamFilterDropdownTextComponent,
  ],
})
export class FileFilterGroupComponent {
  @Output() openAllFilter: EventEmitter<null> = new EventEmitter();

  filterFormService = inject(FileFilterFormService);
}
