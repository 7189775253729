<div class="align-stretch gap-8px">
  <app-roam-filter-dropdown-text
    [label]="'Folder'"
    [value]="filterFormService.folder$ | async"
    (change)="filterFormService.form.patchValue({folder: $event})"
  />

  <app-roam-filter-dropdown-text
    [label]="'File Type'"
    [value]="filterFormService.type$ | async"
    (change)="filterFormService.form.patchValue({type: $event})"
  />

  <app-roam-filter-dropdown-from-to
    [label]="'Date Created'"
    [valueFrom]="filterFormService.createdFrom$ | async"
    [valueTo]="filterFormService.createdTo$ | async"
    (valueFromChange)="filterFormService.form.patchValue({createdFrom: $event})"
    (valueToChange)="filterFormService.form.patchValue({createdTo: $event})"
  />

  <div class="border-vertical mx-8px"></div>

  <button
    mat-stroked-button
    class="button-stroked-rounded text-nowrap"
    (click)="openAllFilter.emit()"
  >
    All Filters
  </button>

  <button
    mat-flat-button
    class="button-stroked-rounded bg-grey"
    (click)="filterFormService.reset()"
  >
    Reset
  </button>
</div>