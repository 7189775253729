import {
  ApplicationRef,
  ComponentFactoryResolver, ComponentRef, Directive, ElementRef,
  EmbeddedViewRef, EventEmitter, HostListener, Injector, Input, OnDestroy, Output, Renderer2,
} from "@angular/core";
import { SidebarMenuSecondaryComponent } from "@app/pages/main/sidebar-menu-secondary/sidebar-menu-secondary.component";

@Directive({
  standalone: true,
  selector: '[appSidebarFloatingMenu]'
})
export class SidebarFloatingMenuDirective implements OnDestroy{

  @Input()
  public title: string = '';

  @Input()
  public isOpen: boolean = false;

  @Input()
  public parentPath: string = '';

  @Input()
  public menus: any;

  @Input()
  public slug: string = '';

  @Output()
  public onSearch: EventEmitter<string> = new EventEmitter<string>();

  private componentRef: ComponentRef<any> | null = null;
  private ignoreClose: boolean = false;
  private top: number = 80;

  private static openedComponentRef: ComponentRef<any> | null = null;
  private touchEndTimeout: any;
  private static anyButtonClicked: boolean = false;

  @HostListener('mouseenter')
  onMouseEnter(): void {
    SidebarFloatingMenuDirective.anyButtonClicked = true;
    this.initializeComponent();
  }

  @HostListener('click', ['$event'])
  onClick(): void {

    if(!this.menus) {
      this.removeSiblings('active');
      this.elementRef.nativeElement.classList.add('active');
      this.elementRef.nativeElement.classList.remove('hover');
    }

    this.destroy();
    SidebarFloatingMenuDirective.anyButtonClicked = false;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if(!this.ignoreClose) {
      this.destroy();
    }
    this.elementRef.nativeElement.disabled = false;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(): void {
    this.handleTouchListener();
    SidebarFloatingMenuDirective.anyButtonClicked = false;
    this.resetSiblingDisabledStates();
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(): void {
    SidebarFloatingMenuDirective.anyButtonClicked = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenWidth();
  }

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private render: Renderer2,
    private injector: Injector) {
  }

  ngOnDestroy(): void {
    this.destroy();
    if (SidebarFloatingMenuDirective.openedComponentRef === this.componentRef) {
      SidebarFloatingMenuDirective.openedComponentRef = null;
    }
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
      this.ignoreClose = false;
      clearTimeout(this.touchEndTimeout);
    }
  }

  private checkScreenWidth(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1185 && this.componentRef) {
      this.destroy();
      this.elementRef.nativeElement.classList.remove('hover');
    }
  }

  private initializeComponent(): void {
    this.removeSiblings();

    if (!!this.menus && !this.isOpen) {
      this.validateOpenedComponent()
      this.createSidebarMenu();
      this.calculateDistanceFromTop();
      this.elementRef.nativeElement.disabled = SidebarFloatingMenuDirective.anyButtonClicked;
    } else {
      this.validateOpenedComponent();
      this.elementRef.nativeElement.disabled = false;
    }
  }

  private validateOpenedComponent(): void {
    if (SidebarFloatingMenuDirective.openedComponentRef !== null) {
      SidebarFloatingMenuDirective.openedComponentRef.destroy();
      SidebarFloatingMenuDirective.openedComponentRef = null;
    }
  }

  private createSidebarMenu(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SidebarMenuSecondaryComponent);
    this.componentRef = componentFactory.create(this.injector);
    this.componentRef.instance.title = this.title;
    this.componentRef.instance.parentPath = this.parentPath;
    this.componentRef.instance.menus = this.menus;
    this.componentRef.instance.top = this.top;
    this.componentRef.instance.slug = this.slug;
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.setTooltipComponentProperties();
    this.ignoreClose = true;
    this.elementRef.nativeElement.classList.add('hover');

    SidebarFloatingMenuDirective.openedComponentRef = this.componentRef;
    this.closeClicked();
    this.clickedMenu();
    this.searchValue();
    this.closeOutside();
  }

  private handleTouchListener(): void {
    this.touchEndTimeout = setTimeout(() => {
      this.elementRef.nativeElement.disabled = false;
    }, 30)
  }

  private resetSiblingDisabledStates(): void {
    // Find and re-enable sibling buttons that might have been disabled
    const siblings = this.getSiblings(this.elementRef.nativeElement);
    siblings.forEach((sibling: any) => {
      sibling.disabled = false;
    });
  }

  private setTooltipComponentProperties(): void {
    if (this.componentRef !== null) {
      const { top } = this.elementRef.nativeElement.getBoundingClientRect();
      const floatingMenu = document.querySelector('.menu-container') as HTMLElement
      const viewportHeight = window.innerHeight;
      const elementHeight = floatingMenu.offsetHeight;
      const bottomPadding = 80;

      // Adjust the top position to ensure it stays within the viewport
      if (top + elementHeight > viewportHeight) {
        this.componentRef.instance.top = viewportHeight - elementHeight - bottomPadding;
      } else {
        this.componentRef.instance.top = top < 255 ? 180 : top - 100;
      }
    }
  }

  private calculateDistanceFromTop(): number {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    this.top = rect.top;
    return rect.top + window.scrollY;
  }

  private getSiblings(elem: HTMLElement): HTMLElement[] {
    const siblings: HTMLElement[] = [];
    let sibling = elem.parentNode?.firstChild as HTMLElement;

    do {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
    } while (sibling.nextSibling && (sibling = sibling.nextSibling as HTMLElement));

    return siblings;
  }

  private removeSiblings(cls: string = 'hover'): void {
    const siblings: NodeListOf<Element> = document.querySelectorAll(`.mobile-short-menu .${cls}`);
    siblings.forEach((sibling: Element) => {
      sibling.classList.remove(cls);
    });
  }

  private searchValue(): void {
    this.componentRef?.instance.onSearch.subscribe((value: any) => {
      this.onSearch.emit(value)
    })
  }

  private closeClicked(): void {
    this.componentRef?.instance.onCloseClicked.subscribe(() => {
      this.elementRef.nativeElement.classList.remove('hover');
      this.render.setStyle(this.elementRef.nativeElement, 'content', 'unset!important');
      this.destroy();
    });
  }

  private closeOutside(): void {
    this.componentRef?.instance.onCloseOutside.subscribe((event:Event) => {
      const clickedInsideMobileMenu = (event.target as HTMLElement).classList.contains('mobile-short-menu');
      if(!clickedInsideMobileMenu) {
        this.elementRef.nativeElement.classList.remove('hover');
        this.destroy();
      }
    });
  }

  private clickedMenu(): void {
    this.componentRef?.instance.onMenuClicked.subscribe(() => {
      if (this.menus) {
        this.removeSiblings('active');

        if (!this.elementRef.nativeElement.classList.contains('active')) {
          this.elementRef.nativeElement.classList.add('active');
        }
        this.elementRef.nativeElement.classList.remove('hover');
        this.destroy();
      }
    });
  }
}
