import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { RoamLayoutModule } from "@app/shared/components/roam-layout/roam-layout.module";
import { RoamDialogMdcComponent } from './roam-dialog-mdc/roam-dialog-mdc.component';
import { RoamDialogTypeOneComponent } from './roam-dialog-type-one/roam-dialog-type-one.component';
import { LlcStripeDialogComponent } from './llc-stripe-dialog/llc-stripe-dialog.component';
import { RoamDialogTypeTwoComponent } from './roam-dialog-type-two/roam-dialog-type-two.component';


@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    RoamDialogTypeOneComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    RoamLayoutModule,
    RoamDialogMdcComponent,
    LlcStripeDialogComponent,
    RoamDialogTypeTwoComponent
  ],
  exports: [
    ConfirmationDialogComponent,
    RoamDialogMdcComponent,
    RoamDialogTypeOneComponent,
    RoamDialogTypeTwoComponent
  ]
})
export class RoamDialogModule { }
