<div
  class="document__list"
  *ngIf="!(loading.loading$ | async); else loader"
>
  <div
    class="justify-between"
    style="margin-bottom: 30px"
  >
    <span *ngIf="selectedRow.length === 0 && !currentPath">
      All Files
    </span>

    <div *ngIf="!selectedRow.length; else docSelected">
      <ul
        class="breadcrumb"
        *ngIf="currentPath"
      >
        <li
          *ngIf="folderList.length <= 2"
          (click)="folderBreadcrumbs()"
        >
          All Files
        </li>

        <li
          *ngIf="folderList.length > 2"
          class="d-flex"
        >
          <div
            class="icon-in"
            (click)="folderBreadcrumbs()"
          >
            <img
              [alt]="icon.inDoc.alt"
              [src]="icon.inDoc.src"
            />
          </div>
        </li>

        <li
          *ngFor="let folder of folderList"
          (click)="folderBreadcrumbs(folder)"
        >
          {{ folder }}
        </li>
      </ul>
    </div>

    <div>
      <ng-template #docSelected>
        <app-document-action-file
          [fileSelected]="selectedRow"
          (onReset)="resetSelectedRow()"
        />
      </ng-template>
    </div>

    <app-document-action
      [isSelected]="selectedRow.length > 0"
      (clickKnowledgeBase)="openKnowledgeBaseDialog()"
      (clickSend)="openDocumentEmailDialog()"
      (clickShare)="openDocumentShareDialog()"
      (layoutChange)="switchLayout()"
    />
  </div>

  <div
    class="table-document-list"
    *ngIf="!isCardLayout"
    style="overflow-x: auto"
  >
    <app-table
      class="table-set"
      [additionalClass]="'column-last-w25'"
      [isLoading]="isLoading"
      [tableConfig]="tableConfig"
      (mouseOverChange)="onMouseOverChange($event)"
      (selectData)="onSelectData($event)"
      category="house"
    >
      <ng-template
        #customTemplate
        let-key="key"
        let-data="data"
      >
        <ng-container *ngIf="key === 'name'">
          <div class="align-center">
            <div class="align-center icon-file">
              <img
                alt="Icon"
                style="margin-right: 10px"
                [src]="getIconSource(data)"
              />
            </div>

            <div class="set-file-name">
              {{ data.name }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="key === 'size'">
          <div>{{ data.documentFolderType === 'folder' ? '' : (data.size | bytesize) }}</div>
        </ng-container>

        <ng-container *ngIf="key === 'selection'">

          <div
            class="justify-between gap-12 doc-action"
            [class.show]="data.isHover || data.isSelected "
          >

            <div class="align-center gap-12">
              <roam-icon name="more-horizontal"></roam-icon>

              <!-- HOVER ACTIONS -->
              <div
                class="doc-action--show"
                [class.d-none]="isSelectedAll && data.isSelected"
              >
                <doc-action
                  [isNoAi]="data.documentFolderType !== 'document'"
                  (onClick)="openActionDialog($event, data)"
                />
              </div>
            </div>

            <!-- CHECKBOX -->
            <div class="align-center doc-action--show">
              <mat-checkbox
                class="check__file"
                [(ngModel)]="data.isSelected"
                (change)="selectedRowHandler(data)"
              />
            </div>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #selectionTemplate>
        <div>
          <div class="header-action">
            <span>Actions</span>

            <div class="force-right">
              <mat-checkbox
                class="pl-1 check__file"
                [checked]="isSelectedAll"
                [(ngModel)]="isSelectedAll"
                (change)="selectAllData($event.checked)"
              />
            </div>
          </div>

          <div>
            <app-document-mobile-action
              *ngIf="isMobile"
              [isDisable]="selectedRow.length > 0"
              (onSelectFile)="showCheckbox = !showCheckbox"
              (onSwitchLayout)="switchLayout()"
            >
              <ng-container item>
                <div
                  class="checkbox-container"
                  *ngIf="tableConfig.dataSource"
                >
                  <mat-checkbox
                    class="pl-1 check__file"
                    [checked]="isSelectedAll"
                    [(ngModel)]="isSelectedAll"
                    (change)="selectAllData($event.checked)"
                  />
                </div>
              </ng-container>
            </app-document-mobile-action>
          </div>
        </div>
      </ng-template>
    </app-table>
  </div>

  <div *ngIf="isCardLayout">
    <div class="grid__header">
      <div class="card-layout-action">
        <p
          class="fw-600 c-pointer"
          style="width: max-content"
          [matMenuTriggerFor]="gridHeaderMenu"
        >
          Name

          <img
            alt="Arrow Up"
            class="v-align-middle pl-5px"
            src="/assets/svg/ic-up-black.svg"
          />
        </p>

        <mat-menu
          #gridHeaderMenu="matMenu"
          xPosition="after"
          yPosition="below"
        >
          <button mat-menu-item>Name</button>

          <button mat-menu-item>Updated</button>

          <button mat-menu-item>Size</button>
        </mat-menu>

        <div class="pr-16">
          <app-document-mobile-action
            [isDisable]="selectedRow.length > 0"
            [isLayout]="isCardLayout"
            [showCheckbox]="false"
            (onSelectFile)="showCheckbox = !showCheckbox"
            (onSwitchLayout)="switchLayout()"
          />
        </div>
      </div>
    </div>

    <div class="documents-card">
      <div
        class="document-list"
        *ngFor="let item of tableConfig.dataSource"
      >
        <app-doc-card
          [isSelected]="item.isSelected"
          [isShowCheckbox]="showCheckbox"
          [item]="item"
          [title]="item.name"
          (onFolder)="openDocumentFolder(item.path)"
          (onSelected)="selectedRowHandler(item)"
        >
          <ng-container action>
            <app-document-mobile-action-more
              [isFile]="item.extension"
              (onClick)="openActionMobile($event, item)"
            />
          </ng-container>
        </app-doc-card>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="justify-center loader mt-50">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate"
    />
  </div>
</ng-template>