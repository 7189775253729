import { Component, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";

@Component({
  selector: 'text-icon-bg',
  standalone: true,
  imports: [
    CommonModule,
    RoamIconComponent
  ],
  template:`
    <div class="align-center gap-12">
      <div class="icon-bg">
        <roam-icon [name]="icon()" color="primary" [size]="size()"/>
      </div>
      <div>
       <ng-content></ng-content>
      </div>
    </div>
  `
})
export class TextIconBgComponent {

  readonly icon = input<string>();

  readonly label = input<string>();

  readonly size = input<string>('26')
}
