import { Component, input } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';

@Component({
  selector: 'menu-link',
  standalone: true,
  imports: [RouterModule, TranslatePipe, MatMenuModule],
  template: `
  @if(item()?.type && item()?.slug) {
    <a
      mat-menu-item
      [routerLink]="[currentPage(), item()?.type, item()?.slug]"
      routerLinkActive="active">
      <p class="details-description">
        {{ item()!.title | translate }}
      </p>
    </a>
  } @else if(item()?.type && !item()?.slug) {
    <a
      mat-menu-item
      [routerLink]="[currentPage(), item()?.type]"
      routerLinkActive="active">
      <p class="details-description">{{ item()!.title | translate }}</p>
    </a>
  } @else {
    <a
      mat-menu-item
      [routerLink]="[currentPage()]"
      routerLinkActive="active">
      <p class="details-description">{{ item()!.title | translate }}</p>
    </a>
  }
  `,
  styles:[
    `
    .mat-mdc-menu-item {
      overflow: unset;
      padding-left: 0;

      p {
        color: #222222;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 180px;
        border-radius: 9px;
        position: relative;
        padding: 14px 10px;
        height: inherit;
      }

      &:hover {
        background: transparent;

        p {
          background: #c6bec5;
        }
      }

      &.active {
        p {
          color: #91466A;
          background: rgba(143, 134, 147, 0.3);
          font-weight: 500;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 4px;
          height: 100%;
          background: #91466a;
        }
      }
    }
    `
  ]
})
export class MenuLinkComponent {
  item = input<{type?: string; slug?: string; title: string}>();
  currentPage = input<string>();
}
