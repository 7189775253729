import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { iconLib } from '@app/core/const/roam-icon';
import { TableConfigModel } from '@app/shared/components/table/model';
import { IAssociationDocument } from "../../shared/interface";
import { GptService } from '@app/shared/services/gpt.service'
import { KbActionService } from "@app/shared/components/knowledge-base/service/kb-action.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-knowledge-base-form",
  templateUrl: "./knowledge-base-form.dialog.html",
})
export class KnowledgeBaseFormDialog implements OnInit, OnDestroy{
  private icon = iconLib;
  public name: string = "";
  private id: string = "";
  public tableConfig!: TableConfigModel;
  public isLoading!: boolean;
  private subscribers: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documents: IAssociationDocument[];
    },
    private gptService: GptService,
    private kbAction: KbActionService,
    private dialogRef: MatDialogRef<KnowledgeBaseFormDialog>
  ) {}

  ngOnInit(): void {
    this.initTable();
    this.tableConfig.dataSource = this.data.documents;
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(each => each.unsubscribe())
  }

  private initTable(): void {
    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = ["name", "size", "action"];
    this.tableConfig.dataLabel = ["Name", "Token", ""];

    this.tableConfig.dataType["name"] = "custom";
    this.tableConfig.dataType["size"] = "number";
    this.tableConfig.dataType["action"] = "custom";
  }

  public getIconSource(file: any | undefined): string {
    if (!file.extension) {
      return this.icon.folder.src;
    }
    switch (file.extension.toUpperCase()) {
      case "DOC":
        return this.icon.folder.src;
      case "PDF":
        return this.icon.pdf.src;
      default:
        return "/assets/svg/flat-color-icons_file.svg";
    }
  }

  public remove(documentId: number) {
    this.tableConfig.dataSource = this.tableConfig.dataSource.filter(
      (each) => each.id !== documentId,
    );
  }

  public submit() {
    this.isLoading = true;
    let docs = this.tableConfig.dataSource.map((each) => {
      return each.id;
    });

    const newKb = {
      id: this.id,
      propertyId: this.data.documents[0].propertyId,
      name: this.name,
      audiences: [],
      files: docs,
    };

    const sub = this.gptService.createKnowledgeBase(newKb).subscribe((res) => {
      this.kbAction.updateKb(res.knowledgeBase)
      this.isLoading = false;
      this.dialogRef.close();
    });

    this.subscribers.push(sub);
  }

  public saveNewName(name: string) {
    this.name = name;
  }

}
