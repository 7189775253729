import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { PaymentMethod } from '../interfaces/payment-method-list.interface';
import { ResponseSuccess } from '../interfaces/api-response.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
  private apiUrl: string = `${environment.apiUrl}/me/payment-methods`;
  private http = inject(HttpClient);

  createPaymentMethod(payload: any): Observable<ResponseSuccess> {
    return this.http.post<ResponseSuccess>(this.apiUrl, payload, {
      withCredentials: true
    })
  }
  
  paymentMethod(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(`${this.apiUrl}`, {
      withCredentials: true,
    });
  }

  setPaymentDefault(id: string): Observable<ResponseSuccess>{
    return this.http.put<ResponseSuccess>(`${this.apiUrl}/${id}/default`, {
      withCredentials: true
    })
  }

  deletePaymentMethod(id: string): Observable<ResponseSuccess>{
    return this.http.delete<ResponseSuccess>(`${this.apiUrl}/${id}`, {
      withCredentials: true
    })
  }

  stripeSetupIntent(): Observable<{clientSecret: string}> {
    return this.http.post<{clientSecret: string}>(`${this.apiUrl}/setup-intent`, {
      withCredentials: true
    })
  }
}
